<template>
  <HeadlessListbox v-model="selectedOption">
    <div class="relative">
      <HeadlessListboxButton
        class="relative ml-2 flex items-center rounded border border-bgr-300 p-2 pr-8 text-sm font-medium text-txt-strongest duration-200 hover:cursor-pointer hover:bg-bgr-100 focus:outline-none md:ml-3"
      >
        <span :class="{ uppercase: toUpperCase }">{{
          hasKey
            ? selectedOption[objectKey as keyof typeof selectedOption]
            : selectedOption
        }}</span>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <WebccIcon
            name="site/chevron-down"
            filled
            class="h-5 w-5 text-bgr-500"
            aria-hidden="true"
          />
        </span>
      </HeadlessListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <HeadlessListboxOptions
          class="absolute z-10 mt-1 w-full overflow-auto rounded bg-bgr py-1 text-base shadow-lg ring-1 ring-bgr-300 focus:outline-none sm:text-sm"
        >
          <HeadlessListboxOption
            v-for="(option, index) in allOptions"
            v-slot="{ active, selected }"
            :key="
              hasKey
                ? (option[objectKey as keyof typeof option] as string)
                : option + ' ' + index
            "
            :value="option"
            as="template"
            @click="
              () =>
                emit(
                  'onChange',
                  hasKey ? option[objectKey as keyof typeof option] : option,
                )
            "
          >
            <li
              :class="[
                active ? 'bg-thm-light text-thm' : 'text-txt-strong',
                'relative cursor-default select-none py-1 pl-10 pr-4',
              ]"
            >
              <span
                :class="[
                  selected ? '' : 'font-normal',
                  'block',
                  { uppercase: toUpperCase },
                ]"
                >{{
                  hasKey ? option[objectKey as keyof typeof option] : option
                }}</span
              >
              <span
                v-if="selected"
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-thm"
              >
                <WebccIcon
                  name="site/tick"
                  class="h-3 w-3 fill-thm"
                  aria-hidden="true"
                />
              </span>
            </li>
          </HeadlessListboxOption>
        </HeadlessListboxOptions>
      </transition>
    </div>
  </HeadlessListbox>
</template>

<script setup generic="T extends string | number | object" lang="ts">
const emit = defineEmits(['onChange'])
const props = defineProps<{
  selectedValue: string | number
  allOptions: T[]
  objectKey?: string
  hasKey: boolean
  toUpperCase: boolean
}>()

// If the selected value is not in the options, set the first option as selected
const selectedOption = computed(() => {
  let option = props.allOptions.find((option: T) => {
    return props.hasKey
      ? option[props.objectKey as keyof typeof option] === props.selectedValue
      : option === props.selectedValue
  })

  if (!option) {
    option = props.hasKey
      ? props.allOptions[0][props.objectKey as keyof typeof option]
      : props.allOptions[0]
  }

  return option
})
</script>
